<template>
  <div class="productThird">
    <div class="detailTable">
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%;margin: 0 auto;"
        :header-cell-style="{textAlign: 'center',backgroundColor:'#e8edf1',color:'#393a39',fontSize:'18px'}"
        :header-row-style="{height:'75px'}"
        :cell-style="{ textAlign: 'center' }">
        <el-table-column
          prop="date"
          label=""
          min-width="20%">
        </el-table-column>
        <el-table-column
          prop="name"
          label="伦敦金"
          min-width="40%">
        </el-table-column>
        <el-table-column
          prop="address"
          label="伦敦银"
          min-width="40%">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name:'productThird',
  data() {
    return{
      tableData: [{
        date: '合约单位',
        name: '100',
        address: '5000'
      }, {
        date: '货币单位',
        name: 'USD',
        address: 'USD'
      },{
        date: '报价小数点',
        name: '2',
        address: '3'
      }, {
        date: '点差',
        name: '0.5美元',
        address: '0.04美元'
      }, {
        date: '单笔交易手数',
        name: '0.01手～30.00手',
        address: '0.01手～30.00手'
      },{
        date: '手数差值',
        name: '0.01手',
        address: '0.01手'
      }, {
        date: '最小挂单距离',
        name: '50',
        address: '50'
      },{
        date: '初始保证金',
        name: '1000.00美元/手',
        address: '1000.00美元/手'
      }, {
        date: '假期保证金',
        name: '双倍',
        address: '双倍'
      }, {
        date: '锁仓保证金',
        name: '500.00美元/手',
        address: '500.00美元/手'
      },{
        date: '库存费',
        name: '-1.5%/-0.75%',
        address: '-1.5%/-0.75%'
      },{
        date: '三天利息日',
        name: '周一',
        address: '周一'
      }, {
        date: '交易时间GMT+8',
        name: '周一：06:00-24:00 \n 周二：00:00-04:55 06:00-24:00 \n 周三：00:00-04:55 06:00-24:00 \n 周四：00:00-04:55 06:00-24:00 \n 周五：00:00-04:55 06:00-24:00 \n 周六：00:00-04:45',
        address: '周一：06:00-24:00 \n 周二：00:00-04:55 06:00-24:00 \n 周三：00:00-04:55 06:00-24:00 \n 周四：00:00-04:55 06:00-24:00 \n 周五：00:00-04:55 06:00-24:00 \n 周六：00:00-04:45'
      }, {
        date: '结算时间',
        name: '夏今：5点为结筤时间 \n 冬令：6点为结算时间',
        address: '夏今：5点为结筤时间 \n 冬令：6点为结算时间'
      }]
    }
  }
}
</script>

<style lang="less" scoped>
  .productThird{
    width: 100%;
    .detailTable{
      width: 100%;
      box-sizing: border-box;
      padding: 15px 0px 105px;
      margin: 0 auto;
      /deep/ .el-table__row{
        height: 80px;
      }
      /deep/ .el-table__row--striped{
        height: 75px;
      }
      /deep/ .el-table__row td:nth-child(1){
        font-weight: 600 !important;
      }
      /deep/ .el-table .cell {
        white-space: pre-line;
      }
      ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td{
        background: #f7f8fa;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .productThird{
      width: 100%;
      box-sizing: border-box;
      /deep/.detailTable{
        width: 100%;
        box-sizing: border-box;
        padding: 0px 5px 30px 5px;
        margin: 0 auto;
        .el-table__row{
          height: 60px;
        }
        .el-table__row--striped{
          height: 55px;
        }
        .el-table__row td:nth-child(1){
          font-weight: 600 !important;
        }
        .el-table .cell {
          white-space: pre-line;
        }
        ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td{
          background: #f7f8fa;
        }
        .el-table th, .el-table tr{
          height: 48px !important;
          font-size: 14px !important;
        }
        .el-table .cell{
          font-size: 12px;
        }
      }
    }
  }
</style>