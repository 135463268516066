import { render, staticRenderFns } from "./productThird.vue?vue&type=template&id=424bf003&scoped=true&"
import script from "./productThird.vue?vue&type=script&lang=js&"
export * from "./productThird.vue?vue&type=script&lang=js&"
import style0 from "./productThird.vue?vue&type=style&index=0&id=424bf003&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "424bf003",
  null
  
)

export default component.exports